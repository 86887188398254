'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LocaleContext = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var LocaleContext = exports.LocaleContext = _react2['default'].createContext({});
var LocaleProvider = function LocaleProvider(props) {
  return _react2['default'].createElement(
    LocaleContext.Provider,
    { value: { antLocale: (0, _extends3['default'])((0, _extends3['default'])({}, props.locale), { exist: true }) } },
    props.children
  );
};
exports['default'] = _react2['default'].memo(LocaleProvider);