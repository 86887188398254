'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports['default'] = {
    okText: '确定',
    cancelText: '取消',
    buttonText: '按钮'
};
module.exports = exports['default'];